import React from 'react';
import './FloatingIcons.css'; // Assuming you will style using a separate CSS file

import Zali from '../../images/zalo.jpg';
import Mess from '../../images/mess.jpg';

const FloatingIcons = () => {
    return (
        <div className="floating-icons">
            {/*<a href="https://zalo.me" target="_blank" rel="noopener noreferrer">*/}
            {/*    <img className="icon" src={Zali} alt="Zali"/>*/}
            {/*</a>*/}
            <a href="http://m.me/221792507988902" target="_blank" rel="noopener noreferrer">
                <img className="icon mess-icon" src={Mess} alt="Mess"/>
            </a>
        </div>
    );
};

export default FloatingIcons;