import React, { useState } from 'react';
import './Header.css'; // Assuming you will style using a separate CSS file
import logo from '../../images/logo.png';
import logo2 from '../../images/logo3.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false); // Closes the menu after a NavLink is clicked
    };

    return (
        <header className="header">
            <div className="logo" onClick={() => window.location.href = '/'}>
                <img src={logo2} alt="Logo"/>
                <span>Ngọc Bảo</span>
            </div>
            <nav className={`nav ${menuOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <NavLink to="/location" onClick={closeMenu}>Chỉ đường</NavLink>
                    </li>
                    <li>
                        <NavLink to="/repair-process" onClick={closeMenu}>Quy trình sửa chữa</NavLink>
                    </li>
                    <li>
                        <NavLink to="/quality-commitment" onClick={closeMenu}>Cam kết dịch vụ</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact-us" onClick={closeMenu}>Liên hệ với chúng tôi</NavLink>
                    </li>
                </ul>
            </nav>
            <div className="auth-buttons">
                <button className="get-started" onClick={() => window.location.href = 'http://www.facebook.com/donghongocbao/'}>
                    Liên hệ ngay
                </button>
            </div>
            <button className="menu-toggle" onClick={toggleMenu}>
                ☰
            </button>
        </header>
    );
};

export default Header;
