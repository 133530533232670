import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import PageSection from "./components/PageSection/PageSection";
import DownloadSection from "./components/DownloadSection/DownloadSection";
import FeaturesSection from './components/FeaturesSection/FeaturesSection';
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import GuildPageUpdate from "./components/GuildPageUpdate/GuildPageUpdate";
import PrivacyPolicy from './components/Process/PrivacyPolicy';
import Tips from './components/Commitment/Tips';
import GuildDetail from './components/ContactUs/GuildDetail';
import Solutions from './components/Solutions/Solution';
import TestimonialCard from './components/TestimonialCard/TestimonialCard';

function Layout() {
    return (
        <div className="App">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

function Home() {
    return (
        <>
            <FeaturesSection />
            <PageSection />
            <TestimonialCard />
            <DownloadSection />
        </>
    );
}

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="location" element={<GuildPageUpdate />} />
                    <Route path="repair-process" element={<PrivacyPolicy />} />
                    <Route path="quality-commitment" element={<Tips />} />
                    <Route path="contact-us" element={<GuildDetail />} />
                    <Route path="solution" element={<Solutions />} />
                </Route>
            </Routes>
        </Router>
    );
}