import React from 'react';
import './Tips.css';
import Commitment from "../../images/Commitment.png";

const Tips = () => {

    return (
        <div className="tips-guild">
            <br/>
            {/*<h1>*/}
            {/*    <strong>Tips Sử dụng phần mềm H.Work</strong>*/}
            {/*</h1>*/}
            <br/>
            <h2>Cam kết về chất lượng dịch vụ </h2>
            <br/>
            <p><strong>Uy tín và chất lượng: </strong>Tất cả các dịch vụ sửa chữa đều được bảo hành, đảm bảo sự yên tâm cho khách hàng khi sử dụng dịch vụ của chúng tôi.</p>
            <p><strong>Giá cả hợp lý:</strong> Chúng tôi luôn minh bạch trong chi phí và cam kết mang lại giá trị tốt nhất cho khách hàng.</p>
            <p><strong>Thời gian nhanh chóng:</strong> Dịch vụ sửa chữa nhanh gọn, không để quý khách phải chờ đợi lâu.</p>



            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <img src={Commitment} alt="Commitment"  />
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>

        </div>
    );
};

export default Tips;