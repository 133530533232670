import React from 'react';
import './GuildPageUpdate.css';
import Location from   "../../images/Location.jpg";
const GuidePageUpdate = () => {
    return (
        <div className="guide-page-update">
            <h1>
                <strong>Hướng dẫn tìm đường đến Đồng hồ Ngọc Bảo </strong>
            </h1>
            <br />
            <p>
                 Tại đường láng hướng đi Nguyễn Chí Thanh, rẽ phải vào ngõ 594. Đi vào ngõ 594 200m, rẽ phải vào ngách 2. Đồng hồ Bảo Ngọc nắm tại số nhà 4D 594/2 Đường Láng.
            </p>
            <p><br /></p>
            <p><br /></p>
            <p>
                <img src={Location} alt="Location"/>
            </p>

            <p>&nbsp;</p>
            <h1>&nbsp;</h1>
        </div>
    );
};

export default GuidePageUpdate;
