import React from 'react';
import './PrivacyPolicy.css';
import Process from "../../images/Process.png";

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>
                <strong>Quy trình sửa chữa</strong>
            </h1>
            <br/>
            {/*<h2>*/}
            {/*    <strong>1. Introduction</strong>*/}
            {/*</h2>*/}
            <br/>
            <h3>
                <strong>Tiếp nhận, kiểm tra tình trạng của đồng hồ:</strong>
            </h3>
            <p>Chúng tôi sẽ kiểm tra kỹ lưỡng tình trạng đồng hồ của bạn và tư vấn chi tiết về các dịch vụ cần thiết.</p>
            <h3>
                <strong>Báo giá:</strong>
            </h3>
            <p>Sau khi kiểm tra, chúng tôi sẽ báo giá cụ thể trước khi tiến hành sửa chữa.</p>
            <h3>
                <strong>Sửa chữa và bảo dưỡng:</strong>
            </h3>
            <p>Chúng tôi sẽ thực hiện các công việc sửa chữa, thay thế và bảo dưỡng theo yêu cầu.</p>
            <h3>
                <strong>Bàn giao và bảo hành:</strong>
            </h3>
            <p>Đồng hồ sẽ được kiểm tra lần cuối trước khi bàn giao lại cho khách hàng cùng với thông tin bảo hành.</p>
            <br/>
            <p>
            <br/>
                <img src={Process} alt="Process" />
            </p>
        </div>
    );
};

export default PrivacyPolicy;