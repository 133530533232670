import React from 'react';
import './Footer.css';
import img_page_section from "../../images/img_page_section.png"; // Assuming you will style using a separate CSS file
import logo from "../../images/logo.png";
import FloatingIcons from "../FloatingIcons/FloatingIcons";

const Footer = () => {
    return (
        <footer className="footer container">
            {/*<div className="footer-left">*/}
            {/*    <img src={logo} alt="HTI Logo" className="logo"/>*/}
            {/*</div>*/}
            <div className="footer-center">
                {/*<p>Copyright © 2024 Đồng Hồ Ngọc Bảo • <a href="#">Legal</a> • <a href="#">Privacy</a></p>*/}
                <p>Copyright © 2024 Đồng Hồ Ngọc Bảo</p>
                <p>Add: 4D 594/2 đường Láng, Đống Đa, Hà Nội</p>
                <p>Tel: 0912179968 </p>
            </div>
            {/*<div className="footer-right">*/}
            {/*    <a href="#"><img src="linkedin-icon.png" alt="LinkedIn"/></a>*/}
            {/*    <a href="#"><img src="facebook-icon.png" alt="Facebook"/></a>*/}
            {/*    <a href="#"><img src="youtube-icon.png" alt="YouTube"/></a>*/}
            {/*</div>*/}
            <FloatingIcons />
        </footer>
    );
};

export default Footer;