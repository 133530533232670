import React, { useState, useEffect } from 'react';
import './TestimonialCard.css';
import FB1 from   "../../images/Feedback1.png";
import FB2 from   "../../images/FB2.png";
import FB3 from   "../../images/FB3.png";
import NoAva from   "../../images/NoAva.png";
import Ava1 from   "../../images/Ava1.png";
import Ava2 from   "../../images/Ava2.png";
import FB4 from   "../../images/FB4.png";
import FB5 from   "../../images/FB5.png";
import Ava3 from   "../../images/Ava3.png";

const reviews = [
    {
        name: "Lê Phương Ngọc",
        location: "Hà Nội",
        feedback: "Nếu bạn đang tìm một nơi sửa chữa đồng hồ uy tín, tôi khuyên bạn nên đến tiệm của bác Toàn. Bác rất đáng tin cậy và có tay nghề cao.",
        image: Ava3,
        stars: "★★★★★",
        productImage: FB5
    },
    {
        name: "Nguyễn Minh Ngọc",
        location: "Hà Nội",
        feedback: "Bác Toàn có kỹ năng sửa chữa rất cao và luôn tận tâm với từng chiếc đồng hồ của khách. Rất yên tâm khi giao đồng hồ cho bác.",
        image: Ava1,
        stars: "★★★★★",
        productImage: FB3
    },
    {
        name: "Petter",
        location: "England",
        feedback: "As an expat, finding reliable services can be challenging, but Mr Toan made the process easy. Not only did he fix my watch efficiently, but he also explained the issues clearly. A truly professional and welcoming experience!",
        image: NoAva,
        stars: "★★★★★",
        productImage: FB1
    },
    {
        name: "Nguyễn Thị Lệ",
        location: "Hà Nội",
        feedback: "Không chỉ sửa chữa tốt, bác Toàn còn rất thân thiện và nhiệt tình khi tư vấn cho khách hàng. Tôi sẽ tiếp tục ủng hộ tiệm của bác.",
        image: Ava2,
        stars: "★★★★★",
        productImage: FB4
    },
    {
        name: "Collins",
        location: "Australia",
        feedback: "I had my watch repaired here and was incredibly impressed by the level of expertise. Mr Toan was meticulous and restored my timepiece to perfect working order. Highly recommend this place!",
        image: NoAva,
        stars: "★★★★★",
        productImage: FB2
    }

    // Add more reviews as needed
];

const TestimonialCard = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false);

    // Auto-slide every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const handleNext = () => {
        if (!isSliding) {
            setIsSliding(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
                setIsSliding(false);
            }, 500); // Match the CSS transition duration
        }
    };

    const handlePrev = () => {
        if (!isSliding) {
            setIsSliding(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
                setIsSliding(false);
            }, 500); // Match the CSS transition duration
        }
    };

    const handleDotClick = (index) => {
        if (!isSliding && index !== currentIndex) {
            setIsSliding(true);
            setTimeout(() => {
                setCurrentIndex(index);
                setIsSliding(false);
            }, 500); // Match the CSS transition duration
        }
    };

    const { name, location, feedback, image, stars, productImage } = reviews[currentIndex];

    return (
        <div className="testimonial-container">
            <h2 className="testimonial-title">Cảm nhận khách hàng</h2>
            <div className={`testimonial-card ${isSliding ? 'sliding' : ''}`}>
                <div className="testimonial-content">
                    <img
                        src={image}
                        alt={name}
                        className="profile-image"
                    />
                    <h3 className="customer-name">{name}</h3>
                    <p className="location">{location}</p>
                    <p className="feedback">{feedback}</p>
                    <div className="stars">{stars}</div>
                </div>
                <img
                    src={productImage}
                    width={600}
                    height={400}
                    alt="Product"
                    className="product-image"
                />
            </div>
            {/*<div className="testimonial-navigation">*/}
            {/*    <button onClick={handlePrev}>Previous</button>*/}
            {/*    <button onClick={handleNext}>Next</button>*/}
            {/*</div>*/}

            {/* Dots navigation */}
            <div className="testimonial-dots">
                {reviews.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    ></span>
                ))}
            </div>
        </div>

    );
};


export default TestimonialCard;
