import React from 'react';
import './FeaturesSection.css'; // Assuming you will style using a separate CSS file
import TruongChinh from   "../../images/TruongChinh.png";

import Videocall from '../../images/Videocall-1.gif';
import Group_chat_258_236 from '../../images/Group_chat_258_236.gif';
import multi from '../../images/multi.gif';
import Legcat from '../../images/Legcat.gif';

import Zali from '../../images/zalo.jpg';
import Mess from '../../images/mess.jpg';

const FeaturesSection = () => {
    return (
        <div>
            <div className="features-container">
                <div className="about-section-image-wrapper">
                    <img src={TruongChinh} alt="Đồng hồ Ngọc Bảo " />
                </div>
                <div className="col-inner">
                    <h2 className="section-heading-title"> Đồng hồ Ngọc Bảo </h2>
                    <p>Tại Đồng hồ Ngọc Bảo, chúng tôi hiểu rằng mỗi chiếc đồng hồ không chỉ là công cụ đo thời gian, mà còn là một phần kỷ niệm, một món quà tinh tế, hay thậm chí là một tài sản giá trị. Với kinh nghiệm lâu năm trong ngành sửa chữa đồng hồ, chúng tôi cam kết mang đến cho quý khách hàng những dịch vụ chăm sóc và sửa chữa chuyên nghiệp nhất.</p>
                    <p>Chúng tôi cung cấp các dịch vụ sửa chữa toàn diện cho tất cả các loại đồng hồ, từ đồng hồ cơ đến các loại đồng hồ điện tử của các thương hiệu danh tiếng như <em>Rolex, Omega, Patek Philippe, Casio, Seiko</em> và nhiều hãng khác. Dịch vụ sửa chữa đồng hồ tại cửa hàng chúng tôi được quản lý và trực tiếp sửa chữa bởi bác Toàn - một thợ sửa đồng hồ vô cùng tận tâm với 40 năm kinh nghiệm. Với nhiều năm kinh nghiệm trong nghề, bác Toàn không chỉ có kiến thức sâu rộng về các loại đồng hồ mà còn có khả năng khắc phục mọi vấn đề một cách tỉ mỉ và chính xác. Trước đây Đồng hồ Ngọc Bảo có địa chỉ sửa chữa tại 397 Trường Chinh, Thanh Xuân, Hà Nội; nay đã được chuyển về địa chỉ mới tại <strong>số 4D, ngõ 594/2 đường Láng, Đống Đa, Hà Nội</strong></p>
                    <p>Thời gian mở cửa: 08:00 - 20:00 tất cả các ngày trong tuần, bao gồm Chủ nhật và ngày lễ</p>
                    <p><em>Nếu quý khách có nhu cầu sửa chữa sau 18:00 hoặc ngoài giờ làm việc của cửa hàng, vui lòng liên hệ trước để cửa hàng sắp xếp nhân sự thực hiện đảm bảo chất lượng phục vụ quý khách được tốt nhất.</em></p>
                </div>
            </div>
            <h2 className="section-heading-title section-heading-title-2">Dịch vụ sửa chữa chuyên nghiệp</h2>
        </div>
    );
};

export default FeaturesSection;