import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './GuildDetail.css';
import ContactUs from "../../images/ContactUs.png"; // Import the CSS file

const GuildDetail = () => {
//   return (
//     <div className="guild-detail">
//       <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
//         <div className="pdf-viewer">
//           <Viewer fileUrl="/pdf/HDSD_H.Work.pdf" />
//         </div>
//       </Worker>
//     </div>
//   );
// };
    return (
        <div className="privacy-policy">
            <h1>
                <strong>Liên hệ với chúng tôi </strong>
            </h1>
            <br/>
            {/*<h2>*/}
            {/*    <strong>1. Introduction</strong>*/}
            {/*</h2>*/}
            <br/>
            {/*<h3>*/}
            {/*    <strong>Tiếp nhận, kiểm tra tình trạng của đồng hồ:</strong>*/}
            {/*</h3>*/}
            <p>Nếu đồng hồ của bạn đang gặp vấn đề, đừng ngần ngại liên hệ với Đồng hồ Ngọc Bảo. Chúng tôi luôn sẵn sàng phục vụ và mang đến cho bạn sự hài lòng tuyệt đối.</p>
            <p>- Thông tin liên hệ: Bác Toàn, SĐT: 0912179968 (có Zalo).</p>
            <p>- Địa chỉ: Đồng hồ Ngọc Bảo - Số 4D, ngõ 594/2 đường Láng, Đống Đa, Hà Nội.</p>
            <p>- Thời gian làm việc: Từ 8h00 - 20h00 các ngày (Cả T7, CN và ngày lễ). </p>
            <p><em>Nếu quý khách có nhu cầu sửa chữa sau 18:00 hoặc ngoài giờ làm việc của cửa hàng, vui lòng liên hệ trước để cửa hàng sắp xếp nhân sự thực hiện đảm bảo chất lượng phục vụ quý khách được tốt nhất.</em></p>
            <p>- Fanpage: <a href="http://www.facebook.com/donghongocbao">  Facebook Fanpage  </a> </p>
            <p>Hãy để chúng tôi giúp chiếc đồng hồ của bạn trở lại như mới, giữ gìn những giá trị thời gian quý giá của bạn.</p>
            <br/>
            <img src={ContactUs} alt="ContactUs"  />
            {/*height="1600" width="1132"*/}
        </div>
    );
};

export default GuildDetail;