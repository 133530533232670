import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './Solution.css'; // Import the CSS file


const Solutions = () => {
  // return (
  //   <div className="solutions">
  //     <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
  //       <div className="pdf-viewer">
  //         <Viewer fileUrl="/pdf/HWork_Solution.pdf" />
  //       </div>
  //     </Worker>
  //   </div>
  // );
    return (
        <div className="privacy-policy">
            <h1>
                <strong>Quy trình sửa chữa</strong>
            </h1>
            <br/>

        </div>
    );
};

export default Solutions;