import React from 'react';
import './PageSection.css';
import thayday from  "../../images/Thayday.jfif";
import repair from  "../../images/Repair.png";
import Pin from  "../../images/Pin.png";
import WR from   "../../images/watch-repairs.jpg";

import img_page_section from "../../images/img_page_section.png"; // Assuming you will style using a separate CSS file
import post_image from "../../images/post_image.png";

const PageSection = () => {
    return (
        <div>
            <div class="service-item">
                <div class="service-item-row">
                    <div class="service-image">
                        {/* <img src="https://spadohieu.net/wp-content/uploads/2023/07/hue-spa-do-hieu-1.jpg" alt="Spa Túi Xách"> */}
                        <img src={repair} alt="Đồng hồ Ngọc Bảo" />
                    </div>
                    <div class="service-content">
                        <h3 class="service-title">Sửa chữa máy đồng hồ</h3>
                        <div class="service-divider"></div>
                        <p class="service-description">
                            Kiểm tra và sửa chữa máy đồng hồ chính xác, đảm bảo đồng hồ của bạn hoạt động trơn tru và bền bỉ.
                        </p>
                        <p class="service-description">
                            <strong>Đồng hồ Ngọc Bảo</strong> cung cấp các dịch vụ sửa chữa toàn diện cho tất cả các loại đồng hồ, từ đồng hồ cơ đến các loại đồng hồ điện tử của các thương hiệu danh tiếng như <em>Rolex, Omega, Patek Philippe, Casio, Seiko</em> và nhiều hãng khác.
                        </p>
                        {/*<a href="https://spadohieu.net/sua-chua-tui-xach-spa-tui-xach-hang-hieu/" target="_self" class="service-button">Xem dịch vụ</a>*/}
                    </div>
                </div>
            </div>  
            <div class="service-item service-item-2">
                <div class="service-item-row">
                    <div class="service-content">
                        <h3 class="service-title">Thay pin và bảo dưỡng</h3>
                        <div class="service-divider"></div>
                        <p class="service-description">
                            Thay pin nhanh chóng với pin chất lượng cao, đồng thời cung cấp dịch vụ bảo dưỡng định kỳ để giữ cho đồng hồ của bạn luôn mới mẻ và chính xác.
                        </p>
                        <p class="service-description">
                            <strong>Đồng hồ Ngọc Bảo</strong> cung cấp các dịch vụ sửa chữa toàn diện cho tất cả các loại đồng hồ, từ đồng hồ cơ đến các loại đồng hồ điện tử của các thương hiệu danh tiếng như <em>Rolex, Omega, Patek Philippe, Casio, Seiko</em> và nhiều hãng khác.
                        </p>
                        {/*<a href="https://spadohieu.net/sua-chua-tui-xach-spa-tui-xach-hang-hieu/" target="_self" class="service-button">Xem dịch vụ</a>*/}
                    </div>
                    <div class="service-image">
                        {/* <img src="https://spadohieu.net/wp-content/uploads/2023/07/hue-spa-do-hieu-1.jpg" alt="Spa Túi Xách"> */}
                        <img src={Pin} alt="Đồng hồ Ngọc Bảo" />
                    </div>
                </div>
            </div>  
            <div class="service-item">
                <div class="service-item-row">
                    <div class="service-image">
                        {/* <img src="https://spadohieu.net/wp-content/uploads/2023/07/hue-spa-do-hieu-1.jpg" alt="Spa Túi Xách"> */}
                        <img src={thayday} alt="Đồng hồ Ngọc Bảo" />
                    </div>
                    <div class="service-content">
                        <h3 class="service-title">Thay dây và linh kiện</h3>
                        <div class="service-divider"></div>
                        <p class="service-description">
                            Cung cấp đa dạng các loại dây đồng hồ (dây da, dây kim loại) phù hợp với phong cách của bạn. Chúng tôi cũng thay thế các linh kiện hư hỏng như mặt kính, núm vặn, đá gắn đồng hồ, các linh kiện bên trong đồng hồ...
                        </p>
                        <p class="service-description">
                            <strong>Đồng hồ Ngọc Bảo</strong> cung cấp các dịch vụ sửa chữa toàn diện cho tất cả các loại đồng hồ, từ đồng hồ cơ đến các loại đồng hồ điện tử của các thương hiệu danh tiếng như <em>Rolex, Omega, Patek Philippe, Casio, Seiko</em> và nhiều hãng khác.
                        </p>
                        {/*<a href="https://spadohieu.net/sua-chua-tui-xach-spa-tui-xach-hang-hieu/" target="_self" class="service-button">Xem dịch vụ</a>*/}
                    </div>
                </div>
            </div>
            <div className="service-item service-item-2">
                <div className="service-item-row">
                    <div className="service-content">
                        <h3 className="service-title">Xử lý đồng hồ bị vào nước và hiệu chỉnh</h3>
                        <div className="service-divider"></div>
                        <p className="service-description">
                            Kiểm tra và xử lý đồng hồ vào nước, điều chỉnh độ chính xác của đồng hồ.
                        </p>
                        <p className="service-description">
                            <strong>Đồng hồ Ngọc Bảo</strong> cung cấp các dịch vụ sửa chữa toàn diện cho tất cả các loại đồng hồ, từ đồng hồ cơ đến các loại đồng hồ điện tử của các thương hiệu danh tiếng như <em>Rolex, Omega, Patek Philippe, Casio, Seiko</em> và nhiều hãng khác.
                        </p>
                        {/*<a href="https://spadohieu.net/sua-chua-tui-xach-spa-tui-xach-hang-hieu/" target="_self" className="service-button">Xem dịch vụ</a>*/}
                    </div>
                    <div className="service-image">
                        {/* <img src="https://spadohieu.net/wp-content/uploads/2023/07/hue-spa-do-hieu-1.jpg" alt="Spa Túi Xách"> */}
                        <img src={WR} alt="Đồng hồ Ngọc Bảo" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageSection;