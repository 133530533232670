import React, {useState} from 'react';
import './DownloadSection.css'; // For custom styles
import AppleStore from '../../images/App-store-US-alt.svg';
import GooglePlay from '../../images/Google-Play-US.svg';
import qrGooglePlay from '../../images/qr_google_play.jpg';
import qrAppleStore from '../../images/qr_apple_store.jpg';

const DownloadSection = () => {

    return (
        <div class="row align-middle service-item-row" id="row-312641208">
            {/* <div id="col-1717687736" class="col medium-6 small-12 large-6">
                <div class="col-inner">
                    <div class="img has-hover" id="image_1409759844">
                        <div class="img-inner dark">
                            <img src="https://spadohieu.net/wp-content/uploads/2023/07/hue-spa-do-hieu.png" alt="Chuyên Gia SPA Đồ Hiệu" />
                        </div>
                    </div>
                </div>
            </div>

            <div id="col-346628517" class="col article-text-wrapper medium-6 small-12 large-6">
                <div class="col-inner text-left">
                    <h3 class="article-title">SPA Túi Xách</h3>
                    <div class="is-divider divider"></div>
                    <p>Những chiếc túi xách hàng hiệu sau một thời gian sử dụng thường sẽ bị bạc, bị hỏng các phụ kiện, phần da bị sờn rách dẫn đến ảnh hưởng thẩm mỹ và giá trị của chiếc túi xách hàng hiệu.</p>
                    <p><strong>Huệ SPA Đồ Hiệu</strong> cung cấp dịch vụ sửa chữa, spa những chiếc túi xách da hàng hiệu như: <em>Louis Vuitton, Dior, Gucci, Hermes, Prada, Fendi, Chanel …</em> Đảm bảo đưa thẩm mỹ những chiếc túi như ban đầu</p>
                    <a href="https://spadohieu.net/sua-chua-tui-xach-spa-tui-xach-hang-hieu/" target="_self" class="button primary is-outline">
                        <span>Xem dịch vụ</span>
                    </a>
                </div>
            </div> */}
        </div>
    );
};

export default DownloadSection;
